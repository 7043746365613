@import "./../../../../styles/variables";

.active {
  & span:first-child::before {
    color: $secondary;
  }
}

.childActive {
  & span:first-child::before {
    color: $primary;
  }
}

.childMockActive {
  & span:first-child::before {
    color: $secondary;
  }
}
