.loader {
  border: 5px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #ef5da8;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  &.small {
    width: 25px;
    height: 25px;
    border-width: 3px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
