@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import "./styles/variables";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-poppins;
  font-weight: 400;
  transition: all 0.4s ease;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 16px;
  scroll-behavior: smooth;
  position: relative;
}

body {
  margin: 0;
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.recharts-wrapper {
  margin: auto;
}

.yellow-icon::before {
  color: #febb3a;
}

.nav-icon {
  right: -5px;
}

.form-control {
  margin: 1rem 0;
  width: 100%;
}

.form-label {
  margin: 0.5rem 0;
  display: inline-block;

  .required {
    color: red;
  }
}

.link {
  cursor: pointer;
  color: #2082f4;
}

.grid-auto-row {
  grid-template-columns: repeat(auto-fill, 16rem);
}

.child-menu::after {
  content: "";
  position: absolute;
  top: 0;
  left: 22px;
  margin-left: -10px;
  margin-top: -10px;
  width: 0;
  z-index: 1;
  height: 0;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.child-menu.practice::after {
  border-bottom: solid 10px #fee6f3;
}

.child-menu.mock::after {
  border-bottom: solid 10px #d9d9f2;
}

.dashboard-active::after {
  content: "";
  position: absolute;
  bottom: -11px;
  margin-left: -10px;
  margin-top: -10px;
  width: 0;
  z-index: 1;
  height: 0;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  border-top: solid 10px #5858c6;
}

.z-99 {
  z-index: 99;
}

ul.q-options > li::marker {
  font-weight: bold;
}

.profile1-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 180, 33, 0.34) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.profile2-gradient {
  background: linear-gradient(
    180deg,
    rgba(239, 93, 168, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.analytics-table tr th {
  font-weight: bold;
}

.analytics-table tr td,
tr th {
  padding: 0.8rem;
  cursor: pointer;
}

.analytics-table tr.active td {
  background-color: #5858c6;
  color: white;
}
